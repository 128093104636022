






































import {Component, Prop, Vue} from "vue-property-decorator";
import {NotEntity} from "@/entity/NotEntity";
import {NotTurResponseProvider} from "@/services/NotTurService";
import Faizler from "@/components/takip/Belgeler/Faizler.vue";
import ParaBirimi from "@/components/inputs/ParaBirimi.vue";
import Dates from "@/components/inputs/Dates.vue";
import ProtestoIhtar from "@/components/takip/Belgeler/ProtestoIhtar.vue";

@Component({
    components: {Faizler, ParaBirimi, Dates, ProtestoIhtar}
})
export default class NotlarForm extends Vue {
    @Prop()
    value!: NotEntity;

    val: NotEntity = Object.assign({}, this.value);
    notTurleri = NotTurResponseProvider().data;
}
