export enum AdresTuru {
    yurticiev = "yurticiev",
    yurtdisiev = "yurtdisiev",
    depo = "depo",
    yurticiis = "yurticiis",
    yurtdisiis = "yurtdisiis",
    cezaevi = "cezaevi",
    askerlik = "askerlik"
}

export enum AdresTuruLabel {
    yurticiev = "Yurt İçi İkametgah Adresi",
    yurtdisiev = "Yurt Dışı İkametgah Adresi",
    depo = "Depo Adresi",
    yurticiis = "Yurt İçi İş Adresi",
    yurtdisiis = "Yurt Dışı İş Adresi",
    cezaevi = "Cezaevi Adresi",
    askerlik = "Askerlik Adresi"
}