






























import {Component, Mixins, Prop, Vue} from "vue-property-decorator";
import {ProtestoEntity} from "@/entity/AlacakBelgeler/ProtestoEntity";
import {ProtestoIhtarEnum} from "@/enum/ProtestoIhtarEnum";
import {IhtarEntity} from "@/entity/AlacakBelgeler/IhtarEntity";
import NoterIslemForm from "@/components/comps/forms/NoterIslemForm.vue";
import Dates from "@/components/inputs/Dates.vue";
import {mixins} from "vue-class-component";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import Tutar from "@/components/inputs/Tutar.vue";
import ParaBirimi from "@/components/inputs/ParaBirimi.vue";

@Component({
    components: {ParaBirimi, Tutar, Dates, NoterIslemForm}
})
export default class ProtestoIhtar extends Mixins(ObjectInputMixin) {
    ProtestoIhtarEnum = ProtestoIhtarEnum;

    mounted(){
        console.log("pro-ihtar-value",this.value);
    }
}
