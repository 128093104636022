











import {Component, Mixins, Prop, Vue} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import KisiKurumForm from "@/components/kisi/kisiKurumForm.vue";
import Tutar from "@/components/inputs/Tutar.vue";

@Component({
    components: {Tutar, KisiKurumForm}
})
export default class alacakliForm extends Mixins(ObjectInputMixin) {


}
