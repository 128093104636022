



























import {Component, Prop, Vue} from "vue-property-decorator";
import {NoterEntity} from "@/entity/NoterEntity";
import {NoterListResponseProvider} from "@/services/NoterService";
import DeleteButton from "@/components/common-inputs/DeleteButton.vue";


@Component({
    components: {DeleteButton}
})
export default class NoterTumuList extends Vue {
    NoterList: NoterEntity[] = [];
    search: string = "";

    headers = [
        {
            text: "Noter",
            align: "start",
            value: "ad"
        },
        {
            text: "İl",
            value: "il.isim"
        },
        {text: "Seç", value: "actions"}


    ];
    @Prop({})
    value!: NoterEntity;

    mounted() {
        this.NoterList = NoterListResponseProvider().data;
    }

    sendNoter(item:NoterEntity) {
        this.$emit('input',item);
        this.$emit('close');
    }

    close() {
        this.$emit('cancel');
        this.$emit('close');
    }


}

