export enum IletisimBilgisiTuruLabel {
    telSabit = "Sabit Telefon",
    telMobil = "Mobil Telefon",
    eposta = "E-posta",
    webSitesi = "Web Sitesi",
    fax = "Fax",
    sosyalMedya = "Sosyal Medya",
}

export enum IletisimBilgisiTuru {
    telSabit = "telSabit",
    telMobil = "telMobil",
    eposta = "eposta",
    webSitesi = "webSitesi",
    fax = "fax",
    sosyalMedya = "sosyalMedya",
}