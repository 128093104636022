import {BankaEntity} from "@/entity/BankaEntity";

export class BankaListResponse{
    data!:BankaEntity[];
}

export function BankaListResponseProvider(depth:number=3):BankaListResponse{
    return {
        data:[
            {
                id:1,
                bankaKodu:"0712",
                isim:"Garanti Bankası",
                aktif:true
            },
            {
                id:2,
                bankaKodu:"0512",
                isim:"Ziraat Bankası",
                aktif:true
            },
            {
                id:3,
                bankaKodu:"0002",
                isim:"Türkiye İş Bankası",
                aktif:true
            },

        ]
    }
}


