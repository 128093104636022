
































import {Component, Vue} from "vue-property-decorator";
import KisiKurumFiltre from "@/components/inputs/kisiKurum/kisiKurumFiltre.vue";
import KisiKurumListe from "@/components/inputs/kisiKurum/kisiKurumListe.vue";
import {KisiEntity} from "@/entity/KisiEntity";
import {KurumEntity} from "@/entity/KurumEntity";
import {KisiKurumEntity} from "@/entity/KisiKurumEntity";

import IptalButton from "@/components/inputs/IptalButton.vue";
import MuvekkilForm from "@/components/vekalet/muvekkil/MuvekkilForm.vue";

@Component({
    components: {MuvekkilForm, IptalButton,  KisiKurumListe, KisiKurumFiltre}
})
export default class kisiKurum extends Vue {

    state: string = 'filter';

    items!: KisiKurumEntity[];
    filterData = {};
    timer: any | null = null;

    filterChanged(filterData: object) {
        this.searchTimeOut();
    }

    searchTimeOut() {
        if (this.timer) {
            clearTimeout(this.timer);
            this.timer = null;
        }
        this.timer = setTimeout(() => {
            this.lookup();
        }, 800);
    }

    lookup() {
        // Vue.prototype.$http.get('/muvekkil-ara',this.filterData).then( (response:AxiosResponse<Array<KisiKurumEntity>>) => {
        //     this.items=response.data;
        // } ).catch(()=>{
        //
        // });
    }

    pick(item: KisiEntity | KurumEntity) {
        this.$emit('input', item);
        this.$emit('close');
    }

    add() {
        this.state = 'add';
    }

}
