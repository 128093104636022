import {IcraDairesiEntity} from "@/entity/IcraDairesiEntity";
import {IlListResponseProvider} from "@/services/IlService";
import { IlEntity } from '@/entity/IlEntity';

class IcraDairesiListResponse {
    data!: IcraDairesiEntity[]
}

export function IcraDairesiListResponseProvider(depth:number=3): IcraDairesiListResponse {
    let illerResponse = Array<IlEntity>();
    if(--depth > 0)
    {    
        illerResponse = IlListResponseProvider(depth).data;
    }
    return {
        data: [
            {
                id: 1,
                isim: "İzmir 1. İcra Dairesi",
                icraVergiNo: "21312532",
                il: illerResponse[0],
                daireNo: 23,
                ilceMi: false,
            },
            {
                id: 2,
                isim: "İzmir 2. İcra Dairesi",
                icraVergiNo: "21312533",
                il: illerResponse[0],
                daireNo: 24,
                ilceMi: false,
            },
            {
                id: 3,
                isim: "İstanbul 1. İcra Dairesi",
                icraVergiNo: "24523532",
                il: illerResponse[2],
                daireNo: 22,
                ilceMi: false,
            },
            {
                id: 4,
                isim: "Kızılay 22. İcra Dairesi",
                icraVergiNo: "21312533",
                il: illerResponse[1],
                daireNo: 24,
                ilceMi: true,
            },

        ]
    }
}
