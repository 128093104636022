



































import {Component, Vue} from "vue-property-decorator";
import NoterIslemForm from "@/components/comps/forms/NoterIslemForm.vue";
import MuvekkilList from "@/components/vekalet/MuvekkilList.vue";
import NotList from "@/components/comps/lists/NotList.vue";
import {VekaletEntity} from "@/entity/VekaletEntity";
import {NoterIslemEntity} from "@/entity/NoterIslemEntity";

@Component({
    components: {NotList, MuvekkilList, NoterIslemForm}
})
export default class VekaletEkle extends Vue {
    vekalet!: VekaletEntity;

    created() {
        this.vekalet = new VekaletEntity();
        this.vekalet.noterIslem = new NoterIslemEntity();
        this.vekalet.muvekkiller = [];
    }

    mounted() {

    }

    editable = true
}
