import {IlceEntity} from "@/entity/IlceEntity";
import {IlEntity} from "@/entity/IlEntity";
import {IlListResponseProvider} from "@/services/IlService";

export interface IlceListResponse {
    data: Array<IlceEntity>
}


export function IlceListResponseProvider(depth:number=3): IlceListResponse {
    let illerResponse = Array<IlEntity>();
    if(--depth > 0)
    {    
        illerResponse = IlListResponseProvider(depth).data;
    }
    return {
        data: [
            {
                id: 1,
                il: illerResponse[1],
                siraNo: 0,
                isim: "Çankaya"
            },
            {
                id: 2,
                il: illerResponse[1],
                siraNo: 6,
                isim: "Kızılay"
            },
            {
                id: 3,
                il: illerResponse[2],
                siraNo: 2,
                isim: "Sarıyer"
            },
            {
                id: 4,
                il: illerResponse[2],
                siraNo: 56,
                isim: "Gaziosmanpaşa"
            },
            {
                id: 5,
                il: illerResponse[2],
                siraNo: 20,
                isim: "Kadıköy"
            },
            {
                id: 6,
                il: illerResponse[2],
                siraNo: 26,
                isim: "Beşiktaş"
            },
            {
                id: 7,
                il: illerResponse[2],
                siraNo: 16,
                isim: "Şişli"
            },
            {
                id: 8,
                il: illerResponse[0],
                siraNo: 50,
                isim: "Bornova"
            },
            {
                id: 9,
                il: illerResponse[0],
                siraNo: 60,
                isim: "Urla"
            },


        ]
    }
}
