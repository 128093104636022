


















import {Component, Prop, Vue} from "vue-property-decorator";
import {IlEntity} from "@/entity/IlEntity";
import {NoterEntity} from "@/entity/NoterEntity";
import {NoterGecmisListResponseProvider} from "@/services/NoterGecmisService";
import NoterTumuList from "@/components/vekalet/NoterTumuList.vue";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";

@Component({
    components: {NoterTumuList}
})
export default class noterSecim extends ObjectInputMixin {
    gecmisNoterList: NoterEntity[] = [];
    dialog = false;

    yeniNoterSecimi:NoterEntity|null = null;

    get secilebilenNoterListesi():NoterEntity[]{
        return this.gecmisNoterList.concat(
            (this.yeniNoterSecimi ? [this.yeniNoterSecimi] : [] )
        ).concat(
            {
                ad: "Tüm Noterlerde Ara", id: 0, il: new IlEntity()
            }
        )
    }

    mounted() {
        this.gecmisNoterList = NoterGecmisListResponseProvider().data;
    }

    noterSecildi() {
        console.log("bakalım buraya düşecekler mi:", this.localValue);
        if(this.localValue && this.localValue.id == 0){
            this.dialog = true;
        }else{

            this.input();
        }
    }

    noterSecimSuccess(item:NoterEntity){
        this.yeniNoterSecimi = item;
        this.localValue=item;
        this.input();
    }

    noterSecimCancel(){
        this.yeniNoterSecimi=null;
        this.value=null;
    }


}
    // Once upon a time, Kaan used to work for Zirve Bilgisayar :)
