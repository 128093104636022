












import {Component, Prop, Vue} from "vue-property-decorator";
import {KisiEntity} from "@/entity/KisiEntity";
import {KurumEntity} from "@/entity/KurumEntity";
import {KisiKurumEntity} from "@/entity/KisiKurumEntity";

@Component({

})
export default class kisiKurumListe extends Vue{
    @Prop({})
    items!:Array<KisiEntity|KurumEntity>;

    headers=[
        {},
    ];

    pick(item:KisiKurumEntity){
        this.$emit('input',item);
    }

}
