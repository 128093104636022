



































































import {Component, Vue} from "vue-property-decorator";
import TcKimlikNumarasi from "@/components/inputs/TcKimlikNumarasi.vue";
import IptalButton from "@/components/inputs/IptalButton.vue";

@Component({
    components: {IptalButton, TcKimlikNumarasi}
})
export default class kisiKurumFiltre extends Vue {
    kisiMi = false;
    arananTc = "";
    arananAd = "";
    arananSoyad = "";
    arananKurumAd = "";
    arananVergiNo = "";
}
