












































































































import {Component, Mixins, Prop, Vue} from "vue-property-decorator";
import {FaizListResponseProvider} from "@/services/FaizService";
import {FaizHesapSekilListResponseProvider} from "@/services/FaizHesapSekilleriService";
import {mixins} from "vue-class-component";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";

@Component({
    components: {}
})
export default class ProtestoIhtar extends Mixins(ObjectInputMixin) {
    @Prop({default: false})
    toolbar!: boolean;
    faizList = FaizListResponseProvider().data;
    hesapSekilList = FaizHesapSekilListResponseProvider().data;

}
