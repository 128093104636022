
















import {Component, Prop, Vue} from "vue-property-decorator";
import IptalButton from "@/components/inputs/IptalButton.vue";
import kaydetButton from "@/components/inputs/KaydetButton.vue";
import Dates from "@/components/inputs/Dates.vue";
import {NoterIslemEntity} from "@/entity/NoterIslemEntity";
import NoterSecim from "@/components/inputs/NoterSecim.vue";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";

@Component({
    components: {
        NoterSecim,
        Dates,
        IptalButton,
        kaydetButton
    }
})
export default class NoterIslemForm extends ObjectInputMixin {
    @Prop()
    tarihsiz:boolean=false;

};
