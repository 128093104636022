import {IlEntity} from "@/entity/IlEntity";
import { IlceListResponseProvider } from './IlceService';
import { IcraDairesiListResponseProvider } from './IcraDairesiService';
import { IlceEntity } from '@/entity/IlceEntity';
import { IcraDairesiEntity } from '@/entity/IcraDairesiEntity';

export interface IlListResponse {
    data: Array<IlEntity>
}

export function IlListResponseProvider(depth:number=3): IlListResponse {
    let ilcelerResponse = Array<IlceEntity>();
    let icraDaireleriResponse = Array<IcraDairesiEntity>();
    if(--depth > 0){    
        ilcelerResponse = IlceListResponseProvider(depth).data;
        icraDaireleriResponse = IcraDairesiListResponseProvider(depth).data;
    }
    return {
        data: [
            {
                id: 3,
                plakaKodu: 35,
                siraNo: 3,
                isim: "İzmir",
                icraDaireleri: icraDaireleriResponse,
                ilceler: ilcelerResponse
            },
            {
                id: 1,
                plakaKodu: 6,
                siraNo: 2,
                isim: "Ankara",
                icraDaireleri: icraDaireleriResponse,
                ilceler: ilcelerResponse
            },
            {
                id: 2,
                plakaKodu: 34,
                siraNo: 1,
                isim: "İstanbul",
                icraDaireleri: icraDaireleriResponse,
                ilceler: ilcelerResponse
            },
            {
                id: 4,
                plakaKodu: 16,
                siraNo: 1,
                isim: "Bursa",
                icraDaireleri: icraDaireleriResponse,
                ilceler: ilcelerResponse
            },


        ]
    }
}
