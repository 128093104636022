export enum KamuOzel
{
    kamu="kamu",
    ozel="ozel"
}

export enum KamuOzelLabel
{
    kamu="Kamu",
    ozel="Özel"
}