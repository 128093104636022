













import {Component, Prop, Vue} from "vue-property-decorator";
import {SelectableListResponse} from "@/helpers/EnumHelper";

export interface selectable {
    text: string;
    value: string;
}

@Component({})
export default class EnumPicker extends Vue {
    @Prop() label!:string;
    @Prop() value!:any;

    @Prop() enum!:any;
    @Prop() labels!:any;

    localValue = this.value ? this.value : null;

    items = Object.keys(this.enum).map((key) => {
        return <selectable>{
            text: this.labels[key],
            value: this.enum[key],
        }
    });
    mounted(){
        console.log(this.value);
    }
    input() {
        console.log(this.value);
        this.$emit('input', this.localValue);
    }
}
