






























































































































import {Component, Prop, Vue} from "vue-property-decorator";
import {KisiEntity} from "@/entity/KisiEntity";
import {KurumEntity} from "@/entity/KurumEntity";
import KaydetButton from "@/components/inputs/KaydetButton.vue";
import IptalButton from "@/components/inputs/IptalButton.vue";
import {KisiKurumTuru} from "@/enum/KisiKurumTuru";
import TcKimlikNumarasi from "@/components/inputs/TcKimlikNumarasi.vue";
import VergiNumarasi from "@/components/inputs/VergiNumarasi.vue";

@Component({
    components: {
        VergiNumarasi,
        TcKimlikNumarasi,
        IptalButton,
        KaydetButton

    },
})
export default class MuvekkilForm extends Vue {
    title = "Ekle";
    @Prop({})
    value!: KisiEntity | KurumEntity | null;
    item: KisiEntity | KurumEntity | null = null;
    kisiKurumTuru = KisiKurumTuru;

    get sahisMi(): boolean {
        if (this.item)
            return this.item.kisiKurumTuru == KisiKurumTuru.sahis;
        return false;
    }


    mounted() {
        if (this.value) {
            this.title = "Düzenle";
            this.item = Object.assign({}, this.value);
            console.log("gelen item:", this.item);
            console.log("sahis mııı :", this.sahisMi);
        } else {
            this.item = new KisiEntity();
        }
    }

    save() {
        this.$emit("save");
    }

    close() {
        this.$emit("cancel");
    }
};
