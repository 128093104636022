import {ParaBirimiEntity} from "@/entity/ParaBirimiEntity";

class ParaBirimiListResponse{
    data!: ParaBirimiEntity[]
}

export function ParaBirimiListResponseProvider(depth:number=3):ParaBirimiListResponse {
    return {data: [
            {
                id:1,
                ad:"Türk Lirası",
                kisaAd:"TL",
                simge:"₺",
                currencyCode:'TRY',
                localeCode:'tr-TR',
            },
            {
                id:2,
                ad:"Dolar",
                kisaAd:"USD",
                simge:"$",
                currencyCode:'USD',
                localeCode:'en-US',
            },
            {
                id:3,
                ad:"Euro",
                kisaAd:"EU",
                simge:"€",
                currencyCode:'EUR',
                localeCode:'de-DE',
            },
    ]}
}

