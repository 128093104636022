import {NoterEntity} from "@/entity/NoterEntity";
import {IlListResponseProvider} from "@/services/IlService";
import { IlEntity } from '@/entity/IlEntity';


export class NoterGecmisListResponse {
    data!: NoterEntity[];
}

export function NoterGecmisListResponseProvider(depth:number=3): NoterGecmisListResponse {
    let illerResponse = Array<IlEntity>();
    if(--depth > 0)
    {
        illerResponse = IlListResponseProvider(depth).data;
    }
    return {
        data: [
            {
                id: 1,
                ad: 'izmir 1. noter',
                il: illerResponse[2]
            },
            {
                id: 2,
                ad: 'izmir 2. noter',
                il: illerResponse[2]
            },
        ]
    }
}
