export enum Cinsiyet {
    bilinmiyor = "bilinmiyor",
    erkek = "erkek",
    kadin = "kadin"
}

export enum CinsiyetLabel {
    bilinmiyor = "Bilinmiyor",
    erkek = "Erkek",
    kadin = "Kadın"
}
