



















































import {Component, Prop, Vue} from "vue-property-decorator";
import {NotEntity} from "@/entity/NotEntity";
import NotForm from "@/components/vekalet/NotForm.vue";

@Component({
    components: {NotForm}
})
export default class NotList extends Vue {

    @Prop({})
    editable!: boolean;
    dialog = false;


    @Prop({})
    items!: NotEntity[]
}
