import {NoterIslemEntity} from "@/entity/NoterIslemEntity";
import {NotEntity} from "@/entity/NotEntity";
import {KisiEntity} from "@/entity/KisiEntity";
import {KurumEntity} from "@/entity/KurumEntity";
import {MuvekkilEntity} from "@/entity/MuvekkilEntity";
import {KisiKurumEntity} from "@/entity/KisiKurumEntity";
import { TakipEntity } from './TakipEntity';

export class VekaletEntity{
    id: number | null = null;
    takip: TakipEntity | null = null;
    ad: string | null = null;
    noterIslem: NoterIslemEntity | null = null;
    notlar: Array<NotEntity> = [];
    muvekkiller: Array<MuvekkilEntity> = [];
}
