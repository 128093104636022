


import {Component, Mixins, Prop, Vue, Watch} from "vue-property-decorator";

@Component({})
export default class SimpleInputMixin extends Vue {
    @Prop() value:any;
    localValue = this.value ? this.value : null;

    @Watch('value')
    onValueChange() {
        this.localValue = this.value;
    }

    input(){
        this.$emit('input',this.localValue);
    }

}
